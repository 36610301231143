import React from 'react'

function PaymentMethodFooter() {
    return (
        <div className='mb-4 mt-5 flex row justify-center items-center'>
            <div className='flex row justify-center items-center text-gray-600 text-xs'>
                <div className='flex row justify-end'>
                    <span className='mr-2'>Powered by</span>
                    <span><img src={"/assets/images/logo-group-grey.png"} alt="Vendy Logo" width={"60px"} height={"20px"} /></span>
                </div>
                <div className='ml-3 mr-3'>
                    <span className='vertical-divider'></span>
                </div>
                <div className='underline flex row'>
                    <span className='mr-2'><a href='#'> Terms of Use</a></span>
                    <span><a href='#'> Privacy</a></span>
                </div>
            </div>
        </div>
    )
}

export default PaymentMethodFooter