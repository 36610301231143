import React, { useEffect, useState } from "react";

import * as Util from "../../common/Util";
import PaymentMethodSelection from "./PaymentMethodSelection";
import CardPayment from "./card/CardPayment";
import BankTransferPayment from "./bank/BankTransferPayment";
import BankPaymentConfirmation from "./bank/BankPaymentConfirmation";

const PaymentForm = ({ props, setReadiness }) => {
    const amount = parseFloat(props.amount.toString()).toFixed(2)

    const [formData, setFormData] = useState({
        domain: props.domain,
        key: props.key,
        phoneNumber: props.phoneNumber || '',
        amount: (amount || '0').replace(/\d(?=(\d{3})+\.)/g, '$&,'),
        currency: props.currency,
        disableButton: false,
        disableReason: '',
        isLoading: false,
        isTest: props.isTest || false,
        useSandbox: props.useSandbox || false,
        channel: 'web',
        chargeCustomer: false,
        step: 0,
        businessName: '',
        businessId: null,
        initialized: false,
        paymentMethod: '',
        meta: props.meta
    })

    useEffect(() => {
        if (!formData.initialized) initiateTransaction()
    }, [props])

    const setWidgetVisibility = (showWidget) => {
        setReadiness({ widgetVisible: showWidget })
    }

    const updateFormData = (newValues) => {
        setFormData((prevState) => {
            return { ...prevState, ...newValues }
        })
    }

    function initiateTransaction() {
        if (
            formData.disableButton ||
            formData.isLoading ||
            !formData.phoneNumber ||
            formData.phoneNumber.length < 7
        )
            return

        let phoneNumber = formData.phoneNumber.replaceAll('+', '').trim()

		updateFormData({ "isLoading": true });
		const env = props.isTest ? (props.useSandbox ? '.sandbox' : '.staging') : '';
		const url = `https://api${env}.vendy.money/public/transactions/initiate-transaction`;
		const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.key };
		const body = {
			"amount": props.amount.toString(),
			"msisdn": phoneNumber,
			"channel": formData.channel,
			"charge_customer": formData.chargeCustomer,
			"meta": formData.meta
		};
		if (props.ref) body.ref = props.ref;

        fetch(url, {
            headers,
            method: 'POST',
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((responseBody) => {
                if (responseBody.status && responseBody.code === 200 && responseBody.data) {
                    let data = responseBody.data
                    updateFormData({
                        step: 1,
                        ref: data.refid,
                        businessName: data.businessName,
                        businessId: data.businessId,
                        initialized: true
                    });
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }

	const updateProps = { formData, updateFormData, setWidgetVisibility };

    return formData.ref ? (
        <div>
            {
                {
                    1: <PaymentMethodSelection {...updateProps} />,
                    2:
                        formData.paymentMethod === 'payment_card' ? (
                            <CardPayment {...updateProps} />
                        ) : (
                            <BankTransferPayment {...updateProps} />
                        ),
                    3: <BankPaymentConfirmation {...updateProps} />
                }[formData.step]
            }

            <div className="text-warning">{formData.disableReason ? <span>{formData.disableReason}</span> : ''}</div>
        </div>
    ) : (
        <div></div>
    )
}

export default PaymentForm
