import { useState, useEffect } from 'react';

import moment from 'moment';

const SECONDS = "s"

export default function useTimer(duration, unit = SECONDS, deadlineDate = new Date().toString()) {
    const [days, setDays] = useState("00");
    const [hours, setHours] = useState("00");
    const [minutes, setMinutes] = useState("00");
    const [seconds, setSeconds] = useState("00");

    let deadlineTime = duration ? moment().add(duration, getMomentUnits(unit)) : moment(deadlineDate)

    const getTime = () => {
        let deadlineTimeMs = Date.parse(deadlineTime.toString());
        let currentTimeMs = Date.now();
        if (currentTimeMs > deadlineTimeMs) return;
        let timeDiffrence = deadlineTimeMs - currentTimeMs;

        let dayValue = Math.floor(timeDiffrence / (1000 * 60 * 60 * 24)).toString();
        let hourValue = Math.floor((timeDiffrence / (1000 * 60 * 60)) % 24).toString();
        let minValue = Math.floor((timeDiffrence / 1000 / 60) % 60).toString();
        let secValue = Math.floor((timeDiffrence / 1000) % 60).toString();

        setDays(dayValue.length < 2 ? '0' + dayValue : dayValue);
        setHours(hourValue.length < 2 ? '0' + hourValue : hourValue);
        setMinutes(minValue.length < 2 ? '0' + minValue : minValue);
        setSeconds(secValue.length < 2 ? '0' + secValue : secValue);
    };

    useEffect(() => {
        const interval = setInterval(() => getTime(deadlineTime), 1000);
        return () => clearInterval(interval);
    }, []);

    return { days, hours, minutes, seconds }
};

function getMomentUnits(unit = SECONDS) {
    switch (unit) {
        case "Y": return "years"
        case "M": return "months"
        case "D": return "days"
        case "H": return "hours"
        case "m": return "minutes"
        case "s": default: return "seconds"
    }
}