import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import * as Util from './common/Util'
import ErrorScreen from './components/outer/ErrorScreen'
import EULAPage from './components/outer/EULAPage'
import PaymentLanding from './components/outer/PaymentLanding'
import PaymentForm from './components/payment/PaymentPage'

function App() {
    const [values, setValues] = useState({
        ready: false,
        initialized: false,
        widgetVisible: false,
        showConfirmation: false,
        showStatus: false,
        key: '',
        ref: '',
        phoneNumber: '',
        isTest: true,
        useSandbox: false,
        currency: '',
        amount: '',
        channel: '',
        meta: {},
        domain: '*'
    });
    function handlePaymentMessage(e) {
        try {
            let message = JSON.parse(e.data)
            if (message['event.type'] === 'new-transaction' && message['data']) {
                let currency = (message['data'].currency || '').trim().toUpperCase()
                if (
                    message['data'].domain &&
                    message['data'].key &&
                    message['data'].amount &&
                    !isNaN(message['data'].amount)
                ) {
                    if (values.initialized) return
                    setState({
                        ready: true,
                        currency,
                        phoneNumber: message['data'].phoneNumber || '',
                        key: message['data'].key,
                        ref: message['data'].ref || '',
                        channel: 'web',
                        amount: message['data'].amount,
                        isTest: message['data'].isTest,
                        useSandbox: message['data'].useSandbox,
                        chargeCustomer: message['data'].chargeCustomer,
                        domain: message['data'].domain,
                        meta: message['data'].meta
                    })
                }
            }
        } catch (_) {}
    }

    useEffect(() => {
        window.addEventListener('message', handlePaymentMessage)
        if (window.location.pathname.trim() === '/') {
            window.top.postMessage(JSON.stringify({ 'event.type': 'ready' }), values.domain)
        }
    }, []);

    const setState = (newValues) => {
        setValues((prevState) => {
            return { ...prevState, ...newValues }
        })
    }

    return values.ready ? (
        <div>
            <div className={values.widgetVisible ? '' : 'hidden'}>
                <button
                    type="button"
                    className="close-button"
                    onClick={(e) => {
                        e.preventDefault()
                        Util.closeFrame(null, values.domain)
                    }}
                >
                    &#10006;
                </button>
                <div style={{ height: '100vh', display: 'flex' }}>
                    <div className={'widget-container'}>
                        <div className="sub-container">
                            {window.location.pathname.trim() !== '/' ? (
                                <ErrorScreen />
                            ) : (
                                <PaymentForm props={values} setReadiness={setState} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div>
            <Router>
                <Routes>
                    {/*<Route exact path="/" element={<Home />} />*/}
                    <Route path="/eula/:reference" element={<EULAPage />} />
                    <Route path="/pay/:transactionRef" element={<PaymentLanding />} />
                    <Route path="/auth/:transactionRef" element={<PaymentLanding />} />
                    {/*<Route element={<NotFound />} />*/}
                </Routes>
            </Router>
        </div>
    )
}

export default App
