import React, { useState } from 'react'

const DEFAULT_TEXT_COLOR = 'rgba(0, 0, 0, 0.60)'

const Tabs = ({ children, selectTabCallback }) => {
    let mainChildren = children[0] ? [...children] : [children]

    const [activeTab, setActiveTab] = useState(mainChildren[0].props.label)
    selectTabCallback(activeTab)

    const handleClick = (e, newActiveTab) => {
        e.preventDefault()
        setActiveTab(newActiveTab)
        selectTabCallback(newActiveTab)
    }

    return (
        <div className="max-w-md mx-auto">
            <div className="px-5">
                <div className="flex border rounded-xl border-gray-100">
                    {mainChildren.map((child) => (
                        <button
                            key={child.props.label}
                            className={`${
                                activeTab === child.props.label ? 'border-5 rounded-xl border-gray-80 bg-gray-100' : ''
                            } flex-1 text-gray-700 font-medium py-2 default-font`}
                            onClick={(e) => handleClick(e, child.props.label)}
                        >
                            <div className="flex row gap-3 justify-center items-center">
                                {child.props.icon ? (
                                    <span className="tab-icon">
                                        {activeTab === child.props.label ? (
                                            <img src={child.props.icon} width={'25px'} height={'25px'} />
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                ) : (
                                    <span></span>
                                )}
                                <span
                                    style={{
                                        color:
                                            activeTab === child.props.label ? child.props.textColor : DEFAULT_TEXT_COLOR
                                    }}
                                >
                                    {child.props.label}
                                </span>
                            </div>
                        </button>
                    ))}
                </div>
            </div>
            <div className="pt-4 pb-2">
                {mainChildren.map((child) => {
                    if (child.props.label === activeTab) {
                        return <div key={child.props.label}>{child.props.children}</div>
                    }
                    return null
                })}
            </div>
        </div>
    )
}

const Tab = ({ label, icon, children, textColor = DEFAULT_TEXT_COLOR }) => {
    return <div label={label} className="hidden"></div>
}
export { Tabs, Tab }
