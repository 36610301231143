import React, { useEffect, useState } from 'react'

import WidgetAmount from '../../../common/components/WidgetAmount'
import WidgetFooter from '../../../common/components/WidgetFooter'
import PaymentMethodFooter from '../PaymentMethodFooter'
import Toast from '../../../common/Toast'

import * as Util from '../../../common/Util'
import { Tabs, Tab } from '../../../common/components/Tabs'
import useTimer from '../../../common/components/Timer'

const TIMER_DURATION = 60
const TIMER_UNIT = 'm' //minutes

function BankTransferPayment({ formData, updateFormData, setWidgetVisibility }) {
    const [paymentAccounts, setpaymentAccounts] = useState([])

    useEffect(() => {
        if (!paymentAccounts.length) getPaymentAccounts()
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault()
        updateFormData({ step: 3, isLoading: true })
        setWidgetVisibility(false)
    }

    const goBack = () => {
        updateFormData({ step: 1, isLoading: true })
        setWidgetVisibility(false)
    }

    function getPaymentAccounts() {
        updateFormData({ isLoading: true })
        const env = formData.isTest ? (formData.useSandbox ? '.sandbox' : '.staging') : ''
        let refid = formData.ref
        const url = `https://api${env}.vendy.money/public/transactions/pay/accounts`
        const headers = { 'Content-Type': 'application/json', Authorization: 'Bearer ' + formData.key }
        const body = { refid }

        fetch(url, {
            headers,
            method: 'POST',
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((responseBody) => {
                if (responseBody.status && responseBody.code === 200 && responseBody.data) {
                    let accounts = responseBody.data.accounts
                    setpaymentAccounts(accounts)
                    updateFormData({ isLoading: false })
                    setWidgetVisibility(true)
                }
            })
            .catch((err) => {
                console.error(err)
                updateFormData({ isLoading: false })
            })
    }

    function getBankBrandColors(bankName) {
        switch (bankName) {
            case 'Wema Bank':
                return '#990D81'
            case 'GT Bank':
            case 'GTBank':
                return '#DD4F03'
            case 'Sterling':
                return '#db1d1d'
            default:
                return ''
        }
    }

    function getBankLogo(bankName) {
        switch (bankName) {
            case 'Wema Bank':
                return '/assets/images/wema-bank.svg'
            case 'GT Bank':
            case 'GTBank':
                return '/assets/images/gt-bank.png'
            case 'Sterling':
                return '/assets/images/sterling-bank.png'
            default:
                return ''
        }
    }

    function selectAccount(bankName) {
        var account = paymentAccounts.find((item) => item.meta.bankName === bankName)
        formData.paymentAccount = account
    }

    return (
        <div>
            {paymentAccounts.length === 0 ? (
                <div></div>
            ) : (
                <>
                    <div className="mt-2">
                        <button className="bordger-gray rounded-md border px-2 py-1" onClick={(e) => goBack()}>
                            <div className="flex row justify-start">
                                <span className="mr-2">
                                    <img src={'/assets/images/icons/back.png'} alt="Back" />
                                </span>
                                <span>Back</span>
                            </div>
                        </button>
                    </div>

                    <div style={{ marginTop: '30px', textAlign: 'center', marginBottom: '20px' }}>
                        <h1 className="font-bold text-lg default-font">Pay via Bank Transfer</h1>
                    </div>
                    <div>
                        <Tabs selectTabCallback={selectAccount}>
                            {paymentAccounts.map((account) => {
                                let name = account?.meta?.bankName
                                return (
                                    <Tab
                                        key={name}
                                        label={name}
                                        icon={getBankLogo(name)}
                                        textColor={getBankBrandColors(name)}
                                    >
                                        <AccountTabWrapper account={account} formData={formData} />
                                    </Tab>
                                )
                            })}
                        </Tabs>
                    </div>

                    <div className="flex justify-start text-xs text-gray-500">
                        <span className="mr-1">
                            <img src={'/assets/images/icons/info.svg'} alt="Info" />
                        </span>
                        <span>
                            Transfer only {Util.getCurrencySymbol(formData.currency.toUpperCase())}
                            {formData.amount} within the given time.
                        </span>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <button
                            className={
                                formData.disableButton ||
                                formData.isLoading ||
                                !formData.phoneNumber ||
                                formData.phoneNumber.length < 7
                                    ? 'btn-disabled'
                                    : 'btn-default'
                            }
                            disabled={
                                formData.disableButton ||
                                formData.isLoading ||
                                !formData.phoneNumber ||
                                formData.phoneNumber.length < 7
                            }
                            style={{ marginBottom: '20px' }}
                            type="submit"
                        >
                            {formData.isLoading ? (
                                <img
                                    className="spinner grayscale"
                                    src={'assets/images/spinner.svg'}
                                    style={{ margin: '-10px 0 -10px', width: '28px' }}
                                    alt="Loading..."
                                />
                            ) : (
                                'I have made a transfer'
                            )}
                        </button>
                    </form>

                    <PaymentMethodFooter />

                    <WidgetFooter formData={formData} />

                    <Toast />
                </>
            )}
        </div>
    )
}

const AccountTabWrapper = ({ account, formData }) => {
    let { minutes, seconds } = useTimer(TIMER_DURATION, TIMER_UNIT)
    return (
        <div>
            <div className="text-left justify-start mb-5">
                <div className="mt-5">
                    <span className="text-xs text-gray-500 mb-4 default-font">Payment to {formData.businessName}</span>
                </div>
                <WidgetAmount formData={formData} />
            </div>
            <div className="rounded-lg p-5 border mb-1 mt-6">
                <div className="flex items-center justify-between mt-1 default-font">
                    <span className="text-xs text-gray-500">Expires In</span>
                    <span className="text-xs font-bold">{minutes + ':' + seconds}</span>
                </div>
                <div className="mt-3 mb-3">
                    <p className="horizontal-divider"></p>
                </div>
                <div className="flex items-center justify-between mt-3 default-font">
                    <span className="text-xs text-gray-500">Bank</span>
                    <span className="text-xs font-bold">{account?.meta?.bankName}</span>
                </div>
                <div className="mt-3 mb-3">
                    <p className="horizontal-divider"></p>
                </div>
                <div className="flex items-center justify-between mt-3 default-font">
                    <span className="text-xs text-gray-500">Account Name</span>
                    <span className="text-xs font-bold">{account?.meta?.accountName}</span>
                </div>
                <div className="mt-3 mb-3">
                    <p className="horizontal-divider"></p>
                </div>
                <div className="flex items-center justify-between mt-3 default-font">
                    <div>
                        <span className="text-xs text-gray-500">Account Number</span>
                    </div>
                    <div className="flex row">
                        <span className="text-xs font-bold mr-1" style={{ color: '#008CFF' }} id="accountCopyIcon">
                            {account?.meta?.accountNumber}
                        </span>
                        <span onClick={(e) => Util.copyItem(account?.meta?.accountNumber, 'Account Number')}>
                            <img src={'/assets/images/icons/copy.svg'} alt="Copy" width={'18px'} height={'18px'} />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BankTransferPayment
