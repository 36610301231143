import React, { useEffect, useState } from "react";

import * as Util from "../../common/Util";

const PaymentLanding = () => {
	const [appButtonEnabled, setAppButtonEnabled] = useState(false);
	const [appButtonClicked, setAppButtonClicked] = useState(false);
	const [warningMessage, setWarningMessage] = useState("");
	const [safariUrl, setSafariBrowser] = useState("");
	const browser = Util.detectBrowser();
	const deviceType = Util.detectDeviceType();
	const osVersion = Util.detectOS().version;
	const isIOS = deviceType === "iOS";

	useEffect(() => {
		const runIOSBrowserCheck = () => {
			if(browser.name !== "Safari") {
				setWarningMessage("Browser not supported, <strong>tap here</strong> to copy this URL and open in your <strong>Safari browser</strong>");
				setSafariBrowser(window.location.href);
			} else {
				setAppButtonEnabled(true);
				if(!Util.isVersionBeforeOrEqual("17.0", osVersion)) {
					setWarningMessage("Oops! It looks like you're using an older iOS version, Upgrade to iOS 17 or newer for an even seamless Vendy experience! 🚀✨");
				}
			}
		};

		if (isIOS) {
			if(navigator.storage && navigator.storage.estimate) {
				navigator.storage.estimate().then(({quota}) => {
					const privateModeEnabled = deviceType.toLowerCase() === "ios" && quota <= 9999999999;
					if(privateModeEnabled) {
						setWarningMessage("You're in <strong>Incognito Mode</strong>, some features may not work properly");
					} else {
						runIOSBrowserCheck();
					}
				});
			} else {
				runIOSBrowserCheck();
			}
		} else {
			setWarningMessage("Something went wrong, follow the steps below to proceed");
		}
	}, []);

	const onClickHandler = (event) => {
		event.preventDefault();
		if(appButtonClicked) {
			window.history.back();
			window.location.href = "https://wa.me/19526810782";
		} else {
			setAppButtonClicked(true);
			const pathSegments = window.location.pathname.split('?')[0].split('/');
			const reference = pathSegments[pathSegments.length - 1];
			window.location.href = `intent://checkout.vendy.money/pay/${reference}#Intent;scheme=https;package=money.vendy.securepay;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;S.browser_fallback_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dmoney.vendy.securepay;end;`;
		}
	};

	const onWarningClick = (event) => {
		event.preventDefault();
		if(!safariUrl) return;
		navigator.clipboard.writeText(window.location.href).then(_ => {});
	};

	return <div className={"payment-widget"}>
		{!isIOS && warningMessage && <a href="#" onClick={onWarningClick} style={{ textDecoration: "none" }}>
			<div className={"notification-banner-warning"}>
				<img src={"/assets/images/banner_info.svg"} alt="info" />
				<div dangerouslySetInnerHTML={{ __html: warningMessage }} />
			</div>
		</a>}
		<div className={"payment-widget-image"} style={{ marginTop: isIOS ? "128px": "58px" }}>
			<img src={"/assets/images/upi-payment.png"} alt={"image"} />
		</div>
		{isIOS && warningMessage && <a href="#" onClick={onWarningClick} style={{ textDecoration: "none" }}>
			<div className={"notification-banner-warning"} style={{ marginBottom: "-20px" }}>
				<img src={"/assets/images/banner_info.svg"} alt="info" />
				<div dangerouslySetInnerHTML={{ __html: warningMessage }} />
			</div>
		</a>}
		<div className={"payment-widget-content"}>
			<h1>Secure Payments<br/>with Vendy</h1>
			<h2>The easiest way to pay for anything</h2>
		</div>
		{!isIOS && <div className={"pay-button"}>
			<a href={"#"} onClick={onClickHandler}>Tap here to pay</a>
		</div>}
		{isIOS && <hr/>}
		<div className={"terms-disclosure"}>
			By proceeding I accept <a href={"#"}>Vendy’s Terms of Service</a> and confirm that I have read Vendy’s <a href={"#"}>Privacy Notice</a>
		</div>
		<div className={"footer-logo"}>
			<img src={"/assets/images/ios-footer-logo.png"} alt={"logo"} />
		</div>
		{appButtonEnabled && <div className={"chat-bubble"}>
			<img src={"/assets/images/ios-bubble-chip.png"} alt={"chip"} />
			<div>
				<h4>Tap to pay</h4>
				<p>Tap the Open button to complete your payment</p>
			</div>
		</div>}
	</div>;
};

export default PaymentLanding;
