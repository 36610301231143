import React from 'react'

function WidgetFooter({ formData }) {
    return (
        <div className='justify-start text-xs'>
            <span>Your payment information is encrypted and securely processed for this transaction, information about your transaction is never shared with merchants or third parties. </span>
            {parseInt(formData.step) < 2 ?
                <span>Learn more about of <a className='text-underlined text-blue-500' href='#'>Terms of Use</a> and <a className='text-underlined text-blue-500' href='#'>Privacy Policies</a></span> :
                <span></span>
            }
        </div>
    )
}

export default WidgetFooter