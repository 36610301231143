import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { toast } from 'react-toastify'

const TIMER_DURATION = 1000

//------------- SWAL ALERTS START-----------------------------

export const showBasicNotification = (text) => {
    withReactContent(Swal).fire(text)
}

export const showTitledNotification = (title, text, callback, icon = 'success') => {
    withReactContent(Swal).fire({
        title: title,
        text: text,
        icon: icon,
        didDestroy: () => {
            callback()
        }
    })
}

export const showPositionNotification = (text, duration = TIMER_DURATION, showConfirm = false) => {
    withReactContent(Swal).fire({
        position: 'top-end',
        icon: 'success',
        title: text,
        showConfirmButton: showConfirm,
        timer: duration
    })
}

export const showConfirmationNotification = (
    title,
    text,
    confirmButtonText,
    confirmCallback,
    cancelCallback,
    icon = 'warning',
    confirmButtonColor = '#3085d6',
    cancelButtonColor = '#d33'
) => {
    withReactContent(Swal)
        .fire({
            title: title,
            text: text,
            icon: icon,
            showCancelButton: true,
            confirmButtonColor: confirmButtonColor,
            cancelButtonColor: cancelButtonColor,
            confirmButtonText: confirmButtonText
        })
        .then((result) => {
            if (result.isConfirmed) {
                confirmCallback()
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                cancelCallback()
            }
        })
}

//------------- SWAL ALERTS END-----------------------------

//todo iplm for diff msg types and other props
export const showToast = (text, type = 'info', position = 'top-right') => {
    toast.success(text, {
        position: position
    })
}
