import React, { useEffect, useState, useRef, useCallback } from 'react'

import WidgetAmount from '../../../common/components/WidgetAmount'
import WidgetFooter from '../../../common/components/WidgetFooter'
import PaymentMethodFooter from '../PaymentMethodFooter'

import * as Util from '../../../common/Util'

const cardIframeId = 'cardIframeId'
const PENDING_TITLE = 'Authorizing Card Payment'

function CardPayment({ formData, updateFormData, setWidgetVisibility }) {
    const [widgetLink, setWidGetLink] = useState(null)
    let transaction = {
        amount: formData.amount,
        currency: formData.currency,
        failed: -1,
        fetched: false
    }
    const [status, updateStatus] = useState({
        title: PENDING_TITLE,
        amount: formData.amount,
        currentProgress: 0,
        nextProgress: 0,
        isLoading: true,
        failed: false,
        subtitle: ''
    })

    const iframeRef = useRef(null)

    useEffect(() => {
        getCardPaymentWidgetLink()
    }, [formData])

    function getCardPaymentWidgetLink() {
        const env = formData.isTest ? (formData.useSandbox ? '.sandbox' : '.staging') : ''
        let refid = formData.ref
        const url = `https://api${env}.vendy.money/public/transactions/pay/cards`
        const headers = { 'Content-Type': 'application/json', Authorization: 'Bearer ' + formData.key }
        const body = { refid }

        fetch(url, {
            headers,
            method: 'POST',
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((responseBody) => {
                if (responseBody.status && responseBody.code === 200 && responseBody.data) {
                    setWidGetLink(`${responseBody.data.url}${responseBody.data.url.indexOf('?') === -1 ? '?':'&'}theme=light`)
                    setWidgetVisibility(true)
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }

    const handleIframeLoad = () => {
        try {
            refreshTransactionDetails()
        } catch (error) {
            console.log(error)
        }
    }

    function setTransaction(newTransaction) {
        transaction = newTransaction
    }

    function completeTransaction() {
        Util.closeFrame(transaction, formData.domain)
    }

    const goBack = () => {
        updateFormData({ step: 1, isLoading: true })
        setWidgetVisibility(false)
    }

    const refreshTransactionDetails = useCallback(() => {
        const env = formData.isTest ? (formData.useSandbox ? '.sandbox' : '.staging') : ''
        const url = `https://api${env}.vendy.money/public/transactions/get-transaction?id=${formData.ref}`
        const headers = { 'Content-Type': 'application/json', Authorization: 'Bearer ' + formData.key }
        fetch(url, { headers })
            .then((response) => response.json())
            .then((data) => {
                if (!(data.status && data.code === 200 && data.data)) return

                let transaction = data.data
                setTransaction({ ...transaction, fetched: true })
                let amount = parseFloat(transaction.amount.toString())
                amount = amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                let isLoading = transaction['failed'] !== 0 && transaction['failed'] !== 1
                let failed =
                    !isLoading &&
                    (transaction['flag'] === 1 || transaction['reversed'] === 1 || transaction['failed'] === 1)

                let title = isLoading ? PENDING_TITLE : 'Transaction ' + (failed ? 'failed' : 'successful')
                let subtitle =
                    'We are processing your payment of ' +
                    transaction.currency.toUpperCase() +
                    amount +
                    ' to ' +
                    formData.businessName

                let currentProgress = status.nextProgress
                let nextProgress = status.nextProgress

                let suffix
                if (transaction['failed'] === 0) {
                    nextProgress = 3
                    suffix = ''
                } else if (transaction['debited'] === 1) {
                    suffix = 'Processing payment...'
                    nextProgress = 2
                } else if (transaction['delivered'] === 1) {
                    nextProgress = 1
                    suffix = null
                }

                if (!isLoading) {
                    subtitle =
                        'Your payment to ' +
                        formData.businessName +
                        ' ' +
                        (failed ? 'failed' : 'was successfully completed')
                    if (failed) {
                        if ((transaction.reason || '').trim().length > 0) subtitle += ' due to ' + transaction.reason
                        subtitle += '.'
                    }
                    setTimeout((_) => completeTransaction(), 5000)
                }

                if (currentProgress !== nextProgress)
                    updateStatus((prevState) => {
                        return { ...prevState, currentProgress, nextProgress }
                    })
                setTimeout(
                    (_) => {
                        updateStatus((prevState) => {
                            return {
                                ...prevState,
                                amount,
                                isLoading,
                                failed,
                                title,
                                subtitle,
                                currentProgress,
                                nextProgress
                            }
                        })
                    },
                    currentProgress !== nextProgress ? 650 : 10
                )
                if (!transaction || transaction['failed'] == null || transaction['failed'] === -1) {
                    setTimeout((_) => refreshTransactionDetails(), 5000)
                }
            })
            .catch((err) => {
                setTimeout((_) => refreshTransactionDetails(), 5000)
            })
    }, [formData.isTest, formData.key, formData.ref, status.nextProgress])

    return (
        <div className="justify-center items-center">
            <div className="mb-2">
                <button className="bordger-gray rounded-md border px-2 py-1" onClick={(e) => goBack()}>
                    <div className="flex row justify-start">
                        <span className="mr-2">
                            <img src={'/assets/images/icons/back.png'} alt="Back" />
                        </span>
                        <span>Back</span>
                    </div>
                </button>
            </div>
            <div className="flex justify-center items-center mt-3 mb-5">
                <h1 className="font-bold text-lg default-font">Pay with Card</h1>
            </div>
            <div style={{ marginTop: '30px', textAlign: 'center' }} className="flex items-center justify-center">
                <WidgetAmount formData={formData} />
            </div>
            <div className="flex items-center justify-center mb-2" style={{ marginTop: '0px' }}>
                <span className="text-sm text-gray-500 mb-3 default-font">Payment to {formData.businessName}</span>
            </div>
            {widgetLink ? (
                status.isLoading ? (
                    <div className="flex justify-center items-center">
                        <iframe
                            src={widgetLink}
                            className="card-flex-container"
                            ref={iframeRef}
                            id={cardIframeId}
                            onLoad={handleIframeLoad}
                        ></iframe>
                    </div>
                ) : (
                    <div>
                        <div className="flex row items-center justify-center mb-5 mt-5">
                            {status.failed ? (
                                <img className="failed-status" src="assets/images/failed.png" alt="failed" />
                            ) : (
                                <img className="success-status" src="assets/images/success.png" alt="successful" />
                            )}
                        </div>
                        <h2 className="container-title">{status.title}</h2>
                        <h4 className="container-title" style={{ fontSize: '12px', marginTop: '8px' }}>
                            {status.subtitle}
                        </h4>
                    </div>
                )
            ) : (
                <div></div>
            )}
            <div style={{ marginTop: '20px' }}>
                <PaymentMethodFooter />
                <WidgetFooter formData={formData} />
            </div>
        </div>
    )
}

export default CardPayment
