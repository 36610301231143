import React, { useEffect, useState } from 'react'

import * as Util from '../../common/Util'

import WidgetFooter from '../../common/components/WidgetFooter'
import WidgetAmount from '../../common/components/WidgetAmount'
import classNames from 'classnames'

const PaymentMethodSelection = ({ formData, updateFormData, setWidgetVisibility }) => {
    const [paymentMethods, setpaymentMethods] = useState([])

    const channelOptions = [
        {
            value: 'payment_card',
            img: '/assets/images/card.svg',
            label: 'Cards',
            name: 'Card',
            description: 'Pay via a debit/credit card'
        },
        {
            value: 'transfer_pay',
            img: '/assets/images/bank.svg',
            label: 'Bank Transfer',
            name: 'Bank Transfer',
            description: 'Pay via a one time transfer'
        }
    ].filter(Boolean)

    useEffect(() => {
        if (paymentMethods.length < 1) getPaymentMethods()
    }, [formData.initialized])

    function getOptionName() {
        let option = channelOptions.find((item) => item.value === formData.paymentMethod)
        return 'Pay via ' + option.name
    }

    function getPaymentMethods() {
        updateFormData({ isLoading: true })
        const env = formData.isTest ? (formData.useSandbox ? '.sandbox' : '.staging') : ''
        let refid = formData.ref
        const url = `https://api${env}.vendy.money/public/transactions/pay/channels`
        const headers = { 'Content-Type': 'application/json', Authorization: 'Bearer ' + formData.key }
        const body = { refid }

        fetch(url, {
            headers,
            method: 'POST',
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((responseBody) => {
                if (responseBody.status && responseBody.code === 200 && responseBody.data) {
                    let methods = responseBody.data
                    console.log(methods)
                    let acceptedMethods = []
                    channelOptions.forEach((option) => {
                        methods.forEach((method) => {
                            console.log(method.identifier)
                            if (method.identifier === 'transfer_pay') acceptedMethods.push(option)
                        })
                    })
                    console.log(acceptedMethods)
                    setpaymentMethods(acceptedMethods)
                    updateFormData({ isLoading: false, paymentMethod: acceptedMethods[0].value })
                    setWidgetVisibility(true)
                }
            })
            .catch((err) => {
                console.error(err)
                updateFormData({ isLoading: false })
            })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setWidgetVisibility(false)
        updateFormData({ step: 2, isLoading: true })
    }

    const PaymentSelectionWrapper = ({ option }) => {
        let selectedMethod = formData.paymentMethod
        let activeClass = selectedMethod === option.value ? 'selected-method' : 'border-gray-200'
        const btnClass = classNames('method-card rounded-lg px-5 pt-5 border', activeClass)
        return (
            <div
                style={{ width: '50%' }}
                onClick={(e) => {
                    e.preventDefault()
                    updateFormData({ paymentMethod: option.value })
                }}
            >
                <div className={btnClass} style={{ width: '100%' }}>
                    <div
                        className="rounded-lg p-5 bg-gray-100 items-center justify-center flex row"
                        style={{ width: '81px', height: '81px', margin: 'auto' }}
                    >
                        <img src={option.img} alt={option.name} width={'50px'} height={'50px'} />
                    </div>
                    <div className="test-sm font-bold mt-2 items-center justify-center flex">
                        <span className="method-card-title" style={{ fontSize: '14px' }}>
                            {option.label}
                        </span>
                    </div>
                    <div className="text-xs text-gray-600 items-center justify-center flex">
                        <span style={{ textAlign: 'center' }}>{option.description}</span>
                    </div>
                    <div className="mt-5"></div>
                </div>

                <div className="text-xs text-gray-600 items-center justify-center flex row mt-3">
                    {selectedMethod === option.value ? (
                        <div>
                            <img
                                src={'/assets/images/checked.svg'}
                                alt={'checked option'}
                                width={'30px'}
                                height={'30px'}
                            />
                        </div>
                    ) : (
                        <div>
                            <img
                                src={'/assets/images/unchecked.svg'}
                                alt={'unchecked option'}
                                width={'30px'}
                                height={'30px'}
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="flex items-center justify-center mt-2">
                <div>
                    <img src={'/assets/images/ios-footer-logo.png'} alt="Vendy" width={'120px'} height={'40px'} />
                </div>
            </div>

            <div style={{ marginTop: '30px', textAlign: 'center' }} className="flex items-center justify-center">
                <WidgetAmount formData={formData} />
            </div>

            <div className="flex items-center justify-center" style={{ marginTop: '0px' }}>
                <span className="text-sm text-gray-500 mb-3 default-font">Payment to {formData.businessName}</span>
            </div>
            <div>
                <div className="flex row items-center justify-center gap-3 mt-5 mb-5">
                    {paymentMethods.map((option) => (
                        <PaymentSelectionWrapper key={option.value} option={option} />
                    ))}
                </div>
                <div className="mt-5">
                    <p className="horizontal-divider"></p>
                </div>
                {/* <div className='flex items-center justify-between mt-5 default-font'>
                    <span className="text-xs text-gray-500 ">Transaction Fee</span>
                    <span className="text-xs font-bold">{Util.getCurrencySymbol(formData.currency.toUpperCase())} 0.00</span>
                </div> */}
                <div className="flex items-center justify-between mt-5 default-font">
                    <span className="text-xs text-gray-500">Billed To</span>
                    <span className="text-xs font-bold">{formData.phoneNumber}</span>
                </div>
                <button
                    className={
                        formData.disableButton || formData.isLoading
                            ? 'btn-disabled btn-submit default-font'
                            : 'btn-default btn-submit default-font'
                    }
                    style={{ marginBottom: '20px', marginTop: '20px' }}
                    disabled={formData.disableButton || formData.isLoading || !formData.paymentMethod}
                    type="button"
                    onClick={handleSubmit}
                >
                    {formData.isLoading ? (
                        <img
                            className="spinner grayscale"
                            src={'assets/images/spinner.svg'}
                            style={{ margin: '-10px 0 -10px', width: '28px' }}
                            alt="Loading..."
                        />
                    ) : (
                        getOptionName(formData.paymentMethod)
                    )}
                </button>
                <WidgetFooter formData={formData} />
            </div>
        </div>
    )
}

export default PaymentMethodSelection
