import React, { useEffect, useState, useCallback } from 'react'

import WidgetAmount from '../../../common/components/WidgetAmount'
import WidgetFooter from '../../../common/components/WidgetFooter'
import PaymentMethodFooter from '../PaymentMethodFooter'

import * as Notif from '../../../common/Notification'
import * as Util from '../../../common/Util'

const VENDY_BLUE = '#008CFF'
const PENDING_TITLE = 'Confirming Bank Transfer'
const SUCCESS_TITLE = 'Transfer Confirmed'
const FAILURE_TITLE = 'Transfer Failed'
const FAILURE_SUB_TITLE = 'Your transfer cannot be confirmed, please try again'

function BankPaymentConfirmation({ formData, setWidgetVisibility }) {
    let transaction = {
        amount: formData.amount,
        currency: formData.currency,
        failed: -1,
        fetched: false
    }

    const [status, updateStatus] = useState({
        title: PENDING_TITLE,
        amount: formData.amount,
        currentProgress: 0,
        nextProgress: 0,
        isLoading: true,
        failed: false,
        subtitle: ''
    })

    function setTransaction(newTransaction) {
        transaction = newTransaction
    }

    const refreshTransactionDetails = useCallback(() => {
        const env = formData.isTest ? (formData.useSandbox ? '.sandbox' : '.staging') : ''
        const url = `https://api${env}.vendy.money/public/transactions/get-transaction?id=${formData.ref}`
        const headers = { 'Content-Type': 'application/json', Authorization: 'Bearer ' + formData.key }
        fetch(url, { headers })
            .then((response) => response.json())
            .then((data) => {
                if (!(data.status && data.code === 200 && data.data)) return

                let transaction = data.data
                setTransaction({ ...transaction, fetched: true })
                let amount = parseFloat(transaction.amount.toString())
                amount = amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                let isLoading = transaction['failed'] !== 0 && transaction['failed'] !== 1
                let failed =
                    !isLoading &&
                    (transaction['flag'] === 1 || transaction['reversed'] === 1 || transaction['failed'] === 1)

                let title = isLoading
                    ? 'Confirming Bank Transfer'
                    : 'Transaction ' + (failed ? 'failed' : 'successful') + ' to ' + formData.businessName + '.'
                let subtitle =
                    'We are trying to confirm a bank transfer of ' +
                    transaction.currency.toUpperCase() +
                    amount +
                    ' to ' +
                    formData.businessName

                let currentProgress = status.nextProgress
                let nextProgress = status.nextProgress

                let suffix
                if (transaction['failed'] === 0) {
                    nextProgress = 3
                    suffix = ''
                } else if (transaction['debited'] === 1) {
                    suffix = 'Processing payment...'
                    nextProgress = 2
                } else if (transaction['delivered'] === 1) {
                    nextProgress = 1
                    suffix = null
                }

                if (!isLoading) {
                    subtitle =
                        'Your payment to ' +
                        formData.businessName +
                        ' ' +
                        (failed ? 'failed' : 'was successfully completed')
                    if (failed) {
                        if ((transaction.reason || '').trim().length > 0) subtitle += ' due to ' + transaction.reason
                        subtitle += '.'
                        sendFailedNotification(retryTransaction)
                    } else {
                        Notif.showTitledNotification(SUCCESS_TITLE, subtitle)
                    }
                }

                if (currentProgress !== nextProgress)
                    updateStatus((prevState) => {
                        return { ...prevState, currentProgress, nextProgress }
                    })
                setTimeout(
                    (_) => {
                        updateStatus((prevState) => {
                            return {
                                ...prevState,
                                amount,
                                isLoading,
                                failed,
                                title,
                                subtitle,
                                currentProgress,
                                nextProgress
                            }
                        })
                    },
                    currentProgress !== nextProgress ? 650 : 10
                )
                if (!transaction || transaction['failed'] == null || transaction['failed'] === -1) {
                    setTimeout((_) => refreshTransactionDetails(), 5000)
                }
            })
            .catch((err) => {
                setTimeout((_) => refreshTransactionDetails(), 5000)
            })
    }, [formData.isTest, formData.key, formData.ref, status.nextProgress])

    useEffect(() => {
        if (!transaction.fetched) {
            setWidgetVisibility(true)

            if (formData.isTest) {
                //mock failed transaction
                mockFailedTransaction()
            } else {
                //check actual transaction status
                let subtitle =
                    'We are trying to confirm a bank transfer of ' +
                    transaction.currency.toUpperCase() +
                    transaction.amount +
                    ' to ' +
                    formData.businessName
                subtitle += ". Waiting for the banks's confirmation..."
                updateStatus((prevState) => {
                    return { ...prevState, subtitle }
                })
                setTimeout((_) => refreshTransactionDetails(), 2000)
            }
        }
    }, [formData, refreshTransactionDetails])

    const retryTransaction = () => {
        let subtitle =
            'We are trying to confirm a bank transfer of ' +
            transaction.currency.toUpperCase() +
            formData.amount +
            ' to ' +
            formData.businessName
        updateStatus((prevState) => {
            return { ...prevState, title: PENDING_TITLE, subtitle, isLoading: true }
        })
        setTimeout((_) => refreshTransactionDetails(), 2000)
    }

    function completeTransaction() {
        setTimeout((_) => Util.closeFrame(transaction, formData.domain), 5000)
    }

    //just for testing purposes. should not be moved to prod
    const mockSuccessTransaction = () => {
        setTransaction({ ...transaction, fetched: true, failed: 0 })
        let subtitle =
            'We are trying to confirm a bank transfer of ' +
            transaction.currency.toUpperCase() +
            formData.amount +
            ' to ' +
            formData.businessName
        updateStatus((prevState) => {
            return { ...prevState, title: PENDING_TITLE, subtitle, isLoading: true }
        })
        setTimeout(() => {
            let subtitle = 'Your payment to ' + formData.businessName + ' was successfully completed'
            updateStatus((prevState) => {
                return { ...prevState, title: SUCCESS_TITLE, subtitle, isLoading: false, failed: false }
            })
            Notif.showTitledNotification(SUCCESS_TITLE, subtitle, completeTransaction)
        }, 30000) //30 secs
    }

    //just for testing purposes. should not be moved to prod
    const mockFailedTransaction = () => {
        setTransaction({ ...transaction, fetched: true, failed: 1 })
        setTimeout(() => {
            updateStatus((prevState) => {
                return {
                    ...prevState,
                    title: FAILURE_TITLE,
                    subtitle: FAILURE_SUB_TITLE,
                    isLoading: false,
                    failed: true
                }
            })
            sendFailedNotification(mockSuccessTransaction)
        }, 30000) //30 secs
    }

    const sendFailedNotification = (callback) => {
        Notif.showConfirmationNotification(
            FAILURE_TITLE,
            FAILURE_SUB_TITLE,
            'Try again',
            callback,
            completeTransaction,
            'info',
            VENDY_BLUE
        )
    }

    return (
        <div>
            <div style={{ marginTop: '40px', textAlign: 'center', marginBottom: '30px' }}>
                <h1 className="font-bold text-lg default-font">Pay via Bank Transfer</h1>
            </div>

            <PaymentAccountWrapper formData={formData} />

            <div className="items-center justify-center mt-5 mb-5">
                {status.isLoading ? (
                    <div className="flex row items-center justify-center mb-5 mt-5">
                        <img className="spinner" src="assets/images/spinner.svg" style={{}} alt="Loading..." />
                    </div>
                ) : (
                    <div className="flex row items-center justify-center mb-5 mt-5">
                        {status.failed ? (
                            <img className="failed-status" src="assets/images/failed.png" alt="failed" />
                        ) : (
                            <img className="success-status" src="assets/images/success.png" alt="successful" />
                        )}
                    </div>
                )}
                <h2 className="container-title">{status.title}</h2>
                <h4 className="container-title" style={{ fontSize: '12px', marginTop: '8px' }}>
                    {status.subtitle}
                </h4>
            </div>
            <div style={{ marginTop: '40px' }}>
                <PaymentMethodFooter />
                <WidgetFooter formData={formData} />
            </div>
        </div>
    )
}

const PaymentAccountWrapper = ({ formData }) => {
    return (
        <div>
            <div className="text-left justify-start mb-5">
                <div className="mt-5">
                    <span className="text-xs text-gray-500 mb-4 default-font">Payment to {formData.businessName}</span>
                </div>
                <WidgetAmount formData={formData} />
            </div>

            <div className="rounded-lg p-5 border mb-1 mt-6">
                <div className="flex items-center justify-between default-font">
                    <span className="text-xs text-gray-500">Bank</span>
                    <span className="text-xs font-bold">{formData.paymentAccount?.meta.bankName}</span>
                </div>
                <div className="mt-3 mb-3">
                    <p className="horizontal-divider"></p>
                </div>
                <div className="flex items-center justify-between mt-3 default-font">
                    <span className="text-xs text-gray-500">Account Name</span>
                    <span className="text-xs font-bold">{formData.paymentAccount?.meta.accountName}</span>
                </div>
                <div className="mt-3 mb-3">
                    <p className="horizontal-divider"></p>
                </div>
                <div className="flex items-center justify-between mt-3 default-font">
                    <span className="text-xs text-gray-500">Billed To</span>
                    <span className="text-xs font-bold">{formData.phoneNumber}</span>
                </div>
            </div>
        </div>
    )
}

export default BankPaymentConfirmation
