import React from 'react'

import * as Util from "../../common/Util";

function WidgetAmount({ formData }) {

    const mainAmount = formData.amount.substring(0, formData.amount.length - 3);
    const minorAmount = formData.amount.substring(formData.amount.length - 3);

    return (
        <div>
            <span className='amount-sub amount-currency'>{Util.getCurrencySymbol(formData.currency.toUpperCase())}</span>
            <span className="currency-amount">{mainAmount}</span>
            <span className="text-gray-500 amount-sub amount-suffix">{minorAmount}</span>
        </div>

    )
}

export default WidgetAmount