import React from 'react'

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';

function Toast() {
    return (
        <div><ToastContainer /></div>
    )
}

export default Toast